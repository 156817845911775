<template>
  <div
    class="w-100 snmp-wireless-trap-block__wrapper"
    :class="{
      'snmp-wireless-trap-block__wrapper-disabled': !enabled,
      'snmp-wireless-trap-block__wrapper-enabled': enabled
    }"
  >
    <div class="w-100 d-flex flex-column p-1">
      <div class="w-100 d-flex flex-row justify-content-between">
        <div>
          <div class="h6">{{ getI18nBlockNameForDisplay(name) }}</div>
        </div>
<!--        <div>-->
<!--          <Switch-component-->
<!--            @change="enableSwitcherChangeHandler"-->
<!--            :label-class="'snmp-wireless-trap-block__enable-checkbox'"-->
<!--            v-model="enabled"-->
<!--            :disabled="isDisable"-->
<!--            :label="''"-->
<!--          />-->
<!--        </div>-->
      </div>
      <div>
        <div class="d-flex flex-row flex-wrap">
          <div class="w-50 mb-q" v-for="option in optionsSortedByName.filter(item=> isShowOptionInWirelessTrapBlock(name, item))" :key="option">
            <div class="">
              <input
                class="form-check-input snmp-wireless-trap-block__option-checkbox"
                @change="changeOptionInputHandler($event, option)"
                type="checkbox"
                v-model="options[option]"
                :id="`${name}-${option}-checkbox`"
              />
              <label
                class="form-check-label snmp-wireless-trap-block__option-checkbox-label pl-q"
                :for="`${name}-${option}-checkbox`"
              >
                {{ getI18nNameForOption(option) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex flex-row justify-content-end">
        <div v-if="Object.keys(this.options).length">
          <div class="form-check">
            <input
              class="form-check-input"
              @change="enableAllInputHandler"
              type="checkbox"
              v-model="enableAllCheckbox"
              :id="`${name}-enable-all-checkbox`"
            />
            <label
              class="form-check-label pl-0 snmp-wireless-trap-block__enable-checkbox-label"
              :for="`${name}-enable-all-checkbox`"
            >
              {{ $t('SNMP.selectAll') }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchComponent from '../../Universal/Switch-component.vue';

export default {
  name: 'SNMPWirelessTrapsBlock',
  components: { SwitchComponent },
  data() {
    return {
      enabled: this.enabledProp,
      enableAllCheckbox: false,
      options: JSON.parse(JSON.stringify(this.optionsProp))
    };
  },
  props: {
    // displayName: {
    //   type: String,
    //   required: true
    // },
    name: {
      // используется для id'щников компонентов, для идентиикции блока в событиях
      type: String,
      required: true
    },
    optionsProp: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    enabledProp: {
      type: Boolean,
      required: true
    },
    isShowOptionInWirelessTrapBlockFunction: {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      // функция для определения показывать или нет опцию вунтри блока
      // и для логики типа "Выбрать все"
      // используется тауже в родительском компоненте, откуда и передается
      type: Function,
      required: true,
      default() {
        return () => true;
      }
    }
  },
  computed: {
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    optionsSortedByName() {
      const options = Object.keys(this.options)
      return options.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });
    },
  },
  methods: {
    isShowOptionInWirelessTrapBlock(blockName, optionName) {
      return this.isShowOptionInWirelessTrapBlockFunction(blockName, optionName);
    },
    capitalizeFirstLetter(word) {
      const firstLetter = word.charAt(0);
      const firstLetterCap = firstLetter.toUpperCase();
      const remainingLetters = word.slice(1);
      const capitalizedWord = firstLetterCap + remainingLetters;
      // console.log(capitalizedWord);
      return capitalizedWord;
    },
    getI18nNameForOption(optionName) {
      const optionNameInLowerCase = optionName.toLowerCase();
      if (this.$te(`SNMP.SNMPWirelessTrapsOptions.${optionNameInLowerCase}`)) {
        return this.$t(`SNMP.SNMPWirelessTrapsOptions.${optionNameInLowerCase}`);
      } else {
        console.warn('no translate for ', optionName);
        return this.getOptionNameForDisplay(optionName);
      }
    },
    getI18nBlockNameForDisplay(displayngName) {
      const displayngNameInLowerCase = displayngName.toLowerCase();
      if (this.$te(`SNMP.SNMPWirelessTrapsBlocks.${displayngNameInLowerCase}`)) {
        return this.$t(`SNMP.SNMPWirelessTrapsBlocks.${displayngNameInLowerCase}`);
      } else {
        console.warn('no translate for ', displayngNameInLowerCase);
        return displayngName;
      }
    },
    getOptionNameForDisplay(optionName) {
      return this.capitalizeFirstLetter(optionName.replaceAll('_', ' '))
    },
    getBlockNameForDisplay(displayngName) {
      return this.capitalizeFirstLetter(displayngName.replaceAll('_', ' '))
    },
    enableSwitcherChangeHandler() {
      this.emitChangeEnabled();
    },
    // enableAllInputHandler(event) {
    //   // console.log(event.target.checked);
    //   this.enableAllCheckbox = event.target.checked;
    //   if (event.target.checked === true) {
    //     Object.keys(this.options).forEach((optionName) => {
    //       this.options[optionName] = true;
    //     });
    //   },
    enableAllInputHandler(event) {
      // console.log(event.target.checked);
      this.enableAllCheckbox = event.target.checked;
      if (event.target.checked === true) {
        Object.keys(this.options).forEach((optionName) => {
          if (this.isShowOptionInWirelessTrapBlock(this.name, optionName)) {
            this.options[optionName] = true;
          }
        });
      }
      // console.log(this.enableAllCheckbox)
      this.emitChangeOption();
    },
    changeOptionInputHandler(event, option) {
      // console.log(event);
      // console.log(option);
      this.options[option] = event.target.checked;
      this.emitChangeOption();
    },
    emitChangeOption() {
      this.$emit('optionChanged', { name: this.name, options: JSON.parse(JSON.stringify(this.options)) });
    },
    emitChangeEnabled() {
      this.$emit('enabledChanged', { name: this.name, enabled: this.enabled });
    }
  },
  watch: {
    enabledProp() {
      this.enabled = this.enabledProp;
    },
    optionsProp: {
      deep: true,
      handler() {
        this.enableAllCheckbox = false;
        this.options = this.optionsProp;
      }
    }
  }
};
</script>

<style scoped>
.snmp-wireless-trap-block__wrapper {
  border: 0.5px dashed lightgray;
  min-height: 60px;
  max-width: 100% !important;
}
.snmp-wireless-trap-block__wrapper-disabled {
  background-color: gainsboro;
  color: black !important;
}
.snmp-wireless-trap-block__wrapper-enabled {
  /*border-color: #00ce00;*/
}
.snmp-wireless-trap-block__enable-checkbox-label {
  font-size: 0.9em;
  margin-left: -0.4em;
}
.snmp-wireless-trap-block__option-checkbox {
  margin-left: 0em;
}
.snmp-wireless-trap-block__option-checkbox-label {
  margin-left: 1em;
  font-size: 0.9em;
}
</style>
<style>
.snmp-wireless-trap-block__enable-checkbox {
  margin-right: 0 !important;
}
</style>
